import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '',
    component: () => import('../layouts/LayoutDefaultLanding'),
    children: [
      {
        path: '',
        name: 'landingPage',
        component: () => import('../views/LandingPage'),
      },
    ]
  },
  // {
  //   path: '*',
  //   redirect: '/404',
  //   name: 'PageNotFound',
  //   component: () => import('../layouts/LayoutNoAppBar'),
  //   children: [
  //     {
  //       path: '',
  //       name: 'Error404',
  //       component: () => import('../views/Error404.vue')
  //     }
  //   ]
  // }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
